import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

import { LATEST_RATE_SHEET } from '../../../data/latest-rate-sheet.data';

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Changes to our Products</h1>
    <p>With effect from Thursday 20 January 2022 we will be making the following changes to our mortgage product range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>Residential Existing Customer Switching Range:</strong></p>
    <ul>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker at 60%, 70%, 75%, 80%, 85% and 90% have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60% LTV has increased </li>
      <li>5 Year Fixed Premier Exclusive at 60% LTV has increased</li>
    </ul>
    <p><strong>Residential Existing Customer Borrowing More Range:</strong></p>
    <ul>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60% LTV has increased</li>
      <li>5 Year Fixed Standard at 60% LTV has increased</li>
      <li>5 Year Fixed Premier Exclusive at 60% LTV has increased</li>
    </ul>
    <p><strong>Residential Remortgage Range:</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60% LTV has reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 80% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 80% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60%, 70% and 80% LTV have reduced </li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 80% LTV have reduced</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70% and 80% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Fee Saver at 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>Residential First Time Buyer Range:</strong></p>
    <ul>
      <li>3 Year Fixed Standard at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 70%, 80%, 85% and 90% LTV have reduced </li>
      <li>5 Year Fixed Standard at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70%, 80%, 85% and 90% LTV have reduced </li>
      <li>5 Year Fixed Premier Exclusive at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>Residential Home Mover Range:</strong></p>
    <ul>
      <li>3 Year Fixed Standard at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 70%, 80%, 85% and 90% LTV have reduced </li>
      <li>5 Year Fixed Standard at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 70%, 80%, 85% and 90% LTV have reduced </li>
      <li>5 Year Fixed Premier Exclusive at 70%, 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Tracker Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
    </ul>
    <p><strong>Buy to Let Existing Customer Switching Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 75% LTV has reduced </li>
      <li>2 Year Fixed Fee Saver at 75% LTV has reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
    </ul>
    <p><strong>Buy to Let Existing Customer Borrowing More Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 75% LTV has reduced</li>
      <li>2 Year Fixed Fee Saver at 75% LTV has reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced </li>
    </ul>
    <p><strong>UK Buy to Let Remortgage Range:</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Standard at 60% and 65% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% and 65% LTV have reduced</li>
    </ul>
    <p><strong>UK Buy to Let Purchase Range:</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 75% LTV has reduced</li>
      <li>5 Year Fixed Standard at 60%, 65% and 75% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60%, 65% and 75% LTV have reduced</li>
    </ul>
    <p>There are no other changes to any of our other product ranges.</p>
    <p>An updated mortgage rate sheet will be published on Thursday 20<sup>th</sup> January 2022 to reflect these changes.</p>
    <p><a title="This PDF opens in a new window" target="_blank" href={LATEST_RATE_SHEET.href}>Link to our latest rate sheet</a></p>
  </NewsArticle>
)

export default Article
